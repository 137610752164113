import React, { useState } from "react";
import Header from "../Header";
import CallToAction from "../CallToAction";
import Footer from "../Footer";

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    interestedRole: "",
    ugCollege: "",
    ugCourse: "",
    ugYear: "",
    pgCollege: "",
    pgCourse: "",
    pgYear: "",
    currentCompany: "",
    jobStartYear: "",
    jobEndYear: "",
    isFresher: false,
    cvLink: "", // Changed from cv to cvLink
  });
  const [hover, setHover] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, isFresher: e.target.checked });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      email: formData.email,
      interestedRole: formData.interestedRole,
      ugCollege: formData.ugCollege,
      ugCourse: formData.ugCourse,
      ugYear: formData.ugYear,
      pgCollege: formData.pgCollege,
      pgCourse: formData.pgCourse,
      pgYear: formData.pgYear,
      currentCompany: formData.currentCompany,
      jobStartYear: formData.jobStartYear,
      jobEndYear: formData.jobEndYear,
      isFresher: formData.isFresher ? "Yes" : "No",
      cvLink: formData.cvLink,
    };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwkFAnLWifzo8Pixl-e4GGYu9-1PRfs9BZf6-PpwiDYnj_ew6z69EoFiGFUk4aybwf7HQ/exec",
        {
          // Replace with your Google Apps Script Web App URL
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          mode: "cors",
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        alert("CV link submitted successfully!");
        setFormData({
          name: "",
          email: "",
          interestedRole: "",
          ugCollege: "",
          ugCourse: "",
          ugYear: "",
          pgCollege: "",
          pgCourse: "",
          pgYear: "",
          currentCompany: "",
          jobStartYear: "",
          jobEndYear: "",
          isFresher: false,
          cvLink: "",
        });
      } else {
        alert("FORM SUBMITTED.THANK YOU");
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("FORM SUBMITTED..THANK YOU");
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      padding: "",
      backgroundColor: "rgba(244, 244, 244, 0.8)",
    },
    section: {
      marginBottom: "40px",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    heading: {
      fontSize: "36px",
      color: "#333",
      marginBottom: "20px",
      textAlign: "center",
    },
    paragraph: {
      fontSize: "18px",
      color: "#555",
      lineHeight: "1.6",
      textAlign: "center",
    },
    list: {
      listStyleType: "none",
      paddingLeft: "0",
      textAlign: "center",
    },
    listItem: {
      fontSize: "20px",
      color: "#666",
      marginBottom: "10px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "600px",
      margin: "0 auto",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    input: {
      padding: "12px",
      marginBottom: "12px",
      border: "1px solid rgba(221, 221, 221, 0.7)",
      borderRadius: "6px",
      fontSize: "16px",
    },
    button: {
      padding: "12px 24px",
      border: "none",
      borderRadius: "6px",
      backgroundColor: "#007bff",
      color: "#fff",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      alignSelf: "center",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    checkboxLabel: {
      fontSize: "16px",
      color: "#555",
      marginBottom: "12px",
    },
  };

  return (
    <div style={styles.container}>
      <Header />
      <section style={styles.section}>
        <h1 style={styles.heading}>Join Our Team</h1>
        <p style={styles.paragraph}>
          "At ForeMed Life Sciences, our culture is defined by precision,
          collaboration, and a commitment to excellence." ForeMed emphasizes
          precision, collaboration, and continuous learning. Employees are
          encouraged to stay updated with coding standards and regulations while
          working closely with healthcare professionals to ensure accuracy. A
          supportive environment fosters teamwork, professional development, and
          adherence to ethical practices, creating a foundation for high-quality
          coding and client trust.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>Job Postings</h2>
        <ul style={styles.list}>
          While we currently have no job openings, we are always on the lookout
          for talented and passionate individuals to join our team at ForeMed
          Life Sciences. We encourage you to stay connected with us for future
          opportunities. Please feel free to submit your CV link, and we will
          keep it on file for any positions that may arise. Thank you for your
          interest in our company and for considering a career with us. We look
          forward to the possibility of working together in the future.
        </ul>
      </section>

      <section style={styles.section}>
        <h2 style={styles.heading}>Submit Your CV Link</h2>
        <form style={styles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="interestedRole"
            placeholder="Interested Job Role"
            value={formData.interestedRole}
            onChange={handleInputChange}
            style={styles.input}
          />
          <input
            type="text"
            name="ugCollege"
            placeholder="Undergraduate College"
            value={formData.ugCollege}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="ugCourse"
            placeholder="Undergraduate Course"
            value={formData.ugCourse}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="ugYear"
            placeholder="Undergraduate Year of Graduation"
            value={formData.ugYear}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="pgCollege"
            placeholder="Postgraduate College"
            value={formData.pgCollege}
            onChange={handleInputChange}
            style={styles.input}
          />
          <input
            type="text"
            name="pgCourse"
            placeholder="Postgraduate Course"
            value={formData.pgCourse}
            onChange={handleInputChange}
            style={styles.input}
          />
          <input
            type="text"
            name="pgYear"
            placeholder="Postgraduate Year of Graduation"
            value={formData.pgYear}
            onChange={handleInputChange}
            style={styles.input}
          />
          <input
            type="text"
            name="currentCompany"
            placeholder="Current Company (if applicable)"
            value={formData.currentCompany}
            onChange={handleInputChange}
            style={styles.input}
          />
          <input
            type="text"
            name="jobStartYear"
            placeholder="Job Start Year"
            value={formData.jobStartYear}
            onChange={handleInputChange}
            style={styles.input}
            disabled={formData.isFresher}
          />
          <input
            type="text"
            name="jobEndYear"
            placeholder="Job End Year (or 'Present' if current)"
            value={formData.jobEndYear}
            onChange={handleInputChange}
            style={styles.input}
            disabled={formData.isFresher}
          />
          <label style={styles.checkboxLabel}>
            <input
              type="checkbox"
              name="isFresher"
              checked={formData.isFresher}
              onChange={handleCheckboxChange}
              style={{ marginRight: "8px" }}
            />
            Fresher
          </label>
          <input
            type="text"
            name="cvLink"
            placeholder="Google Drive CV Link"
            value={formData.cvLink}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
          <button
            type="submit"
            style={
              hover
                ? { ...styles.button, ...styles.buttonHover }
                : styles.button
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            Submit CV Link
          </button>
        </form>
      </section>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Career;
