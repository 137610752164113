/* eslint-disable eqeqeq */
import React from "react";
import SlickSlider from "../../components/UI/Slick";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import IconBox from "../../components/IconBox";
import Text from "../../components/UI/Text";
import HowWeWorksData from "../../data/HowWeWorks/how-we-works";

const ServiceContentWrap = ({
  service,
  totalService,
  prevService,
  nextService,
}) => {
  var serviceID = new URLSearchParams(window.location.search).get("id");
  console.log(serviceID);

  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <SlickSlider
            settings={{
              className: "service-details-thumb",
              arrows: false,
              dots: true,
            }}
          >
            {service.previewImages.map((previewImg, indx) => (
              <div key={indx}>
                <img
                  src={require("../../assets/img/" + previewImg)}
                  alt={service.title}
                />
              </div>
            ))}
          </SlickSlider>
        </div>

        <div className="service-details-info">
          <h3>{service.title}</h3>
          <p>{service.shortDesc}</p>

          <div className="about-service mt-50 mt-sm-35">
            <h4>About this Service</h4>
            <div
              dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }}
            />
            <List classes="service-feature mt-30">
              {service.features.map((feature, indx) => (
                <LI key={indx}>{feature}</LI>
              ))}
            </List>
          </div>

          <div className="how-we-works-wrap mt-50 mt-sm-35">
            <h4>How ForeMed Life Sciences can assist:</h4>
            <Text styles={{ marginBottom: 20 }}>
              ForeMed Life Sciences boosts revenue with expert medical coding,
              efficient RCM services, and precise medical scribing. We optimize
              the entire revenue cycle—coding, billing, claims management, and
              real-time documentation—to enhance financial outcomes for
              healthcare providers.
            </Text>

            <div className="how-we-works-content service-details-page">
              {HowWeWorksData.map((Object) => {
                if (Object.id == serviceID) {
                  return (
                    <div className="row" key={Object.id}>
                      {Object.features.map((iconbox) => (
                        <IconBox
                          key={iconbox.id}
                          icon={iconbox.icon}
                          title={iconbox.title}
                          text={iconbox.text}
                        />
                      ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="pagination-wrap">
            <ul className="pagination">
              <li className="prev">
                <a
                  href={`${
                    process.env.PUBLIC_URL +
                    `/service/${prevService.title
                      .split(" ")
                      .join("-")
                      .toLocaleLowerCase()}?id=${prevService.id}`
                  }`}
                  className={service.id === 1 ? "disabled" : ""}
                >
                  <i className="fa fa-long-arrow-left" /> Previous
                </a>
              </li>
              <li className="next">
                <a
                  href={`${
                    process.env.PUBLIC_URL +
                    `/service/${nextService.title
                      .split(" ")
                      .join("-")
                      .toLocaleLowerCase()}?id=${nextService.id}`
                  }`}
                  className={service.id === totalService ? "disabled" : ""}
                >
                  Next <i className="fa fa-long-arrow-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentWrap;
