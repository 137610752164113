import React from "react";
import { Link } from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar";

const styles = {
  link: {
    color: "black",
    textDecoration: "none",
    padding: "10px 0",
    display: "block",
  },
  linkHover: {
    color: "grey",
  },
  submenuNav: {
    listStyleType: "none",
    paddingLeft: "20px",
  },
  submenuItem: {
    padding: "5px 0",
  },
  megaMenuNav: {
    display: "flex",
    flexWrap: "wrap",
  },
  megaMenuItem: {
    width: "50%",
  },
  megaMenuList: {
    listStyleType: "none",
    paddingLeft: "20px",
  },
  megaMenuListItem: {
    padding: "5px 0",
  },
};

function NavbarItem() {
  return navbarData.map((item) => (
    <li
      key={item.id}
      className={item.subMenu || item.megaMenu ? "has-submenu" : ""}
    >
      <Link
        to={`${process.env.PUBLIC_URL + item.link}`}
        style={styles.link}
        onMouseOver={(e) =>
          (e.currentTarget.style.color = styles.linkHover.color)
        }
        onMouseOut={(e) => (e.currentTarget.style.color = styles.link.color)}
      >
        {item.title}
      </Link>
      {item.subMenu && (
        <ul className="submenu-nav" style={styles.submenuNav}>
          {item.subMenu.map((subItem, index) => (
            <li key={index} style={styles.submenuItem}>
              <Link
                to={`${process.env.PUBLIC_URL + subItem.link}`}
                style={styles.link}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = styles.linkHover.color)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = styles.link.color)
                }
              >
                {subItem.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
      {item.megaMenu && (
        <ul className="submenu-nav submenu-nav-mega" style={styles.megaMenuNav}>
          {item.megaMenu.map((megaItem, indx) => (
            <li
              key={indx}
              className="mega-menu-item"
              style={styles.megaMenuItem}
            >
              <Link
                to={megaItem.link}
                style={styles.link}
                onMouseOver={(e) =>
                  (e.currentTarget.style.color = styles.linkHover.color)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = styles.link.color)
                }
              >
                {megaItem.title}
              </Link>
              <ul style={styles.megaMenuList}>
                {megaItem.lists.map((listItem, idx) => (
                  <li key={idx} style={styles.megaMenuListItem}>
                    <Link
                      to={`${process.env.PUBLIC_URL + listItem.link}`}
                      style={styles.link}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.color = styles.linkHover.color)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.color = styles.link.color)
                      }
                    >
                      {listItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </li>
  ));
}

export default NavbarItem;
