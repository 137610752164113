import React, { Fragment } from "react";

const ContactInfo = ({ address }) => {
  return (
    <Fragment>
      <div className="widget-item m-0">
        <address>
          <p>{address.line1}</p>
          <p>Phone: {address.phone}</p>
          <p>
            Email: <a href={`mailto:${address.email}`}>{address.email}</a>
          </p>
        </address>
      </div>
      {/* 
      Uncomment the following block if you want to include social network links
      <div className="member-social-icons mt-30">
        {
          socialNetworks.map(social => (
            <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer">
              <i className={`fa fa-${social.networkName}`} />
            </a>
          ))
        }
      </div>
      */}
    </Fragment>
  );
};

export default ContactInfo;
