import React from "react";
import NavbarItem from "./NavbarItem";

const styles = {
  mainMenu: {
    listStyleType: "none",
  },
};

function Navbar(props) {
  return (
    <ul className="main-menu nav" style={styles.mainMenu}>
      <NavbarItem />
    </ul>
  );
}

export default Navbar;
