import React, { useState } from "react";
import FormInput from "../../components/UI/Input";

const From = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    phone_no: "",
    con_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(
      "https://cors-anywhere.herokuapp.com/https://script.google.com/macros/s/AKfycbyWmx3B1he_YWRhBR4SjqYkwPuzqdfiPaWDkSq3QR_auxKTl6J6hEkj__DNLuSLR56f/exec",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.text())
      .then((data) => {
        alert("Form submitted successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="contact-form-wrap">
      <form id="contact-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"first_name"}
              placeholder={"First Name *"}
              value={formData.first_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"last_name"}
              placeholder={"Last Name *"}
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email_address"}
              placeholder={"Email address *"}
              value={formData.email_address}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"phone_no"}
              placeholder={"Phone No"}
              value={formData.phone_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-12">
            <FormInput
              tag={"textarea"}
              name={"con_message"}
              placeholder={"Write Your Message *"}
              value={formData.con_message}
              onChange={handleChange}
            />
            <FormInput tag={"button"} classes={"btn-outline"} />
            <div className="form-message" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default From;
