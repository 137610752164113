import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-one";
import Services from "../components/Services";
// import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
//import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";
// import Testimonial from "../components/Testimonials/home-two";
// import Team from "../components/Team/home-two";
import Box from "../components/box/box";
import ServiceThumb from "../assets/img/image.jpg";

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/page-header.jpg")}
        title="ABOUT US"
        content="ForeMed Life Sciences is dedicated to delivering precise medical coding services and comprehensive revenue cycle management solutions. Our mission is to enhance healthcare efficiency through accurate coding and streamlined processes, ensuring compliance and maximizing revenue for our clients. We strive to be a trusted partner in optimizing financial and operational outcomes for healthcare providers.

"
      />
      <About
        title={"Our Team"}
        heading="Meet Our <br/> Expert Member"
        thumb={ServiceThumb}
        content="<b>Businex</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
      />
      <Box />
      <Services />
      <Features classes={"sm-top"} />
      {/* <Testimonial/>
            <Team/>
            <BrandLogo/>
            <Funfact classes="sp-top"/> */}
      <CallToAction />
      <Footer />
      {/* <LoginRegister /> */}
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
