import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
// import Testimonial from "../components/Testimonials/home-two";
// import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
// import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from "../assets/img/about3.jpg";

const PageService = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/service.jpg")}
        title="OUR SERVICES"
        content="ForeMed Life Sciences: Offering top-tier medical coding and revenue cycle management to streamline financial workflows and optimize revenue for healthcare providers"
      />
      <PageAbout
        title={"Our Services"}
        heading=""
        thumb={ServiceThumb}
        content="Leveraging our extensive expertise, advanced analytics, and pioneering technology, we enable our clients to achieve sustainable health outcomes and offer more personalized care.<br/>We unify various aspects of healthcare to provide a holistic view of an individual’s health. By delivering customized insights, we simplify decision-making and enhance the healthcare experience. Our flexible, cost-effective solutions ensure accessible support, driving improved healthcare options and supporting healthier lives for all.
"
      />
      <Services classes="sm-top" />

      {<PricingTable />}
      {/* <Testimonial />
      <BrandLogo />
      <Funfact classes="sp-top" /> */}
      <CallToAction />
      <Footer />
      {/* <LoginRegister /> */}
      <MobileMenu />
    </Fragment>
  );
};

export default PageService;
