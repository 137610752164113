import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const TransparentBox = styled.div`
  background-color: rgba(255, 255, 255, 0.9); /* Slightly less transparent */
  padding: 30px; /* Increased padding */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced box shadow */
  max-width: 900px; /* Further increased box width */
  margin: 20px auto;
  backdrop-filter: blur(12px); /* Enhanced frosted glass effect */
`;

const Heading = styled.h2`
  font-size: 26px; /* Larger font size */
  color: #222; /* Darker color */
  margin-bottom: 15px; /* Increased margin */
  text-align: center; /* Centered text */
`;

const SubHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px; /* Increased margin */
  padding: 10px; /* Padding for better touch */
  border-radius: 8px; /* Rounded corners for dropdown header */
  background-color: rgba(
    0,
    0,
    0,
    0.05
  ); /* Light background for dropdown header */
  transition: background-color 0.3s; /* Smooth background color transition */

  &:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Darker background on hover */
  }
`;

const SubHeading = styled.h3`
  font-size: 22px; /* Larger font size */
  color: #333; /* Darker color */
  margin: 0; /* Remove default margin */
`;

const Icon = styled.span`
  font-size: 24px; /* Larger icon */
  color: #333; /* Darker color */
  transition: transform 0.3s, color 0.3s; /* Smooth transition for rotation and color */
  transform: ${({ visible }) => (visible ? "rotate(180deg)" : "rotate(0)")};
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0; /* Remove default margin */
  display: ${({ visible }) => (visible ? "block" : "none")};
  padding: 10px 0; /* Padding around the list */
`;

const ListItem = styled.li`
  font-size: 18px; /* Larger font size */
  color: #555; /* Darker color */
  margin-bottom: 10px; /* Increased margin */
  padding: 10px; /* Padding around items */
  border-bottom: 1px solid #ddd; /* Light border for separation */
  transition: background-color 0.3s; /* Smooth background color transition */

  &:last-child {
    border-bottom: none; /* Remove border from last item */
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05); /* Light background on hover */
  }
`;

// React component
const TransparentBoxWithList = ({ heading, sublists }) => {
  const [visibleLists, setVisibleLists] = useState(
    sublists.reduce((acc, _, index) => ({ ...acc, [index]: false }), {})
  );
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const toggleListVisibility = (index) => {
    setVisibleLists((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <TransparentBox>
      <Heading>{heading}</Heading>
      {sublists.map((sublist, index) => (
        <div
          key={index}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <SubHeadingWrapper onClick={() => toggleListVisibility(index)}>
            <SubHeading>{sublist.subHeading}</SubHeading>
            <Icon visible={visibleLists[index]}>▼</Icon>
          </SubHeadingWrapper>
          <List visible={visibleLists[index] && hoveredIndex === index}>
            {sublist.items.map((item, idx) => (
              <ListItem key={idx}>{item}</ListItem>
            ))}
          </List>
        </div>
      ))}
    </TransparentBox>
  );
};

// Usage example
const Example = () => {
  const data = [
    {
      subHeading: "Coding Guidelines and Regulations",
      items: ["ICD-10-CM", "CPT", "HCPCS", "HIPAA", "CMS Guidelines"],
    },
    {
      subHeading: "Compliance Audit and Review",
      items: [
        "ForeMed Life Sciences conducts regular compliance audits to ensure that our coding practices adhere to all applicable regulations and guidelines. We work closely with our clients to identify potential compliance risks and develop strategies for mitigation.",
      ],
    },
    {
      subHeading: "Coding Education and Training",
      items: [
        "We offer comprehensive coding education and training programs for our clients' staff. These programs help ensure that your team is up-to-date on the latest coding guidelines and best practices, enhancing accuracy and compliance.",
      ],
    },
  ];

  return (
    <div>
      <TransparentBoxWithList
        heading="Expertise in Healthcare Coding and Compliance"
        sublists={data}
      />
    </div>
  );
};

export default Example;
